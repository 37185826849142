<template>
  <div class="w-full h-full flex flex-col justify-between">
    <!-- Screens info -->
    <div class="vx-row" >
      <div id="displays_active" class="display_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer">
        <vx-tooltip color="dark" text="Pantallas activas actualmente">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="MonitorIcon"
            color="primary"
            :statistic="stats.displays_active_count"
            statisticTitle="Pantallas activas" >
            <!-- dynamic slot -->
            <p class="text-3xl">{{stats.percent_display_active}} Q{{ currentQ }}</p>
          </statistics-card-line>
        </vx-tooltip>
      </div>
      <div id="displays_for_sales" class="display_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer">
        <vx-tooltip color="dark" text="Pantallas que están a la venta actualmente">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="MonitorIcon"
            color="primary"
            :statistic="stats.displays_for_sale_count"
            statisticTitle="Pantallas a la venta" />
        </vx-tooltip>
      </div>
      <div id="displays_active_camlytics" class="display_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer">
        <vx-tooltip color="dark" text="Pantallas activas actualmente con camlytics">
          <statistics-card-line
            hideChart
            icon-right
            class="mb-base"
            icon="VideoIcon"
            color="success"
            :statistic="stats.displays_active_camlytics_count"
            statisticTitle="Pantallas activas con cámara" />
        </vx-tooltip>
      </div>
    </div>
    <!-- Chart & countries-->
    <div class="vx-row">
      <div class="vx-col w-full mb-base min-h-full lg:w-2/3 xl:w-2/3 vs-con-loading__container" >
        <year-goals-chart
          :series="series"
          :average="average"
        />
      </div>
      <!-- sales for country container -->
      <div class="vx-col w-full mb-base lg:w-1/3 min-h-full xl:w-1/3 vs-con-loading__container" >
        <sales-country-panel 
          :countries="stats.total_price_with_profit_usd_by_country"
          :currentQ="currentQ"
        />
      </div>
    </div>
    <!-- Other info -->
    <div class="" >
      <span class="pl-4 mb-4 block">Datos del día</span>
      <div class="vx-row">
        <div id="campaigns_active_count" class="campaigns_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer">
          <vx-tooltip color="dark" text="Campañas activas hoy">
            <statistics-card-line
              hideChart
              icon-right
              icon="BookIcon"
              color="primary"
              class="mb-base"
              :statistic="stats.campaigns_active_count"
              statisticTitle="Campañas activas" />
            </vx-tooltip>
        </div>
        <div id="display_campaigns_active" class="campaigns_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer">
          <vx-tooltip color="dark" text="Pantallas con campañas activas hoy">
            <statistics-card-line
              hideChart
              icon-right
              icon="MonitorIcon"
              color="primary"
              class="mb-base"
              :statistic="stats.display_campaigns_active"
              statisticTitle="Pantallas con campañas activas" />
            </vx-tooltip>
        </div>
        <div id="content_display_campaigns_active" class="campaigns_stats vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 vs-con-loading__container cursor-pointer">
          <vx-tooltip color="dark" text="Creativos activos de hoy">
            <statistics-card-line
              hideChart
              icon-right
              icon="SunIcon"
              class="mb-base"
              color="success"
              :statistic="stats.content_display_campaigns_active"
              statisticTitle="Creativos activos" />
            </vx-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import SalesCountryPanel from '@/components/sales-country-panel/SalesCountryPanel.vue'
import YearGoalsChart from '@/components/year-goals-chart/YearGoalsChart.vue'
import appConfig from '@/../appConfig.js'
import moment from 'moment'
import { getSumOKR, getToCurrentMonthOKR } from '../utils/okr'
import { getCountryCode, getQSalesPercentByCountry } from '../utils/countries'
import { getPercentDisplaysActive } from '../utils/display'

export default {
  components: {
    StatisticsCardLine,
    YearGoalsChart,
    SalesCountryPanel
  },
  props: {
    token: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      currentQ: moment().quarter(),
      stats: {
        displays_active: [],
        displays_active_count: '-',
        percent_display_active: '',
        company_with_displays_active: [],
        company_with_displays_active_count: '-',

        displays_disabled: [],
        displays_disabled_count: '-',
        company_with_displays_disabled: [],
        company_with_displays_disabled_count: '-',

        displays_for_sale: [],
        displays_for_sale_count: '-',
        displays_active_for_sale: [],
        displays_active_for_sale_count: '-',
        displays_disabled_for_sale: [],
        displays_disabled_for_sale_count: '-',

        displays_not_use_cms: [],
        displays_not_use_cms_count: '-',

        displays_active_camlytics: [],
        displays_active_camlytics_count: '-',

        campaigns_active: [],
        campaigns_active_count: '-',
        content_display_campaigns_active: '-',
        content_display_approved: '-',
        content_display_rejected: '-',
        display_campaigns_active: '-',
        // ARS
        total_price_ars: '-',
        total_price_with_commission_ars: '-',
        total_price_with_commission_ars_by_country: [],
        total_price_with_profit_ars: '-',
        total_price_with_profit_ars_by_country: [],
        platform_profit_ars: '-',
        // USD
        total_price_usd: '-',
        total_price_with_commission_usd: '-',
        total_price_with_commission_usd_by_country: [],
        total_price_with_profit_usd: '-',
        total_price_with_profit_usd_by_country: [],
        platform_profit_usd: '-'
      },
      series: [],
      average: '0',
      filterDateCampaigns: {
        from: moment().startOf('day').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      },
      datesCurrentQ : {
        from: moment().startOf('quarter').format('YYYY-MM-DD'),
        to: moment().endOf('quarter').format('YYYY-MM-DD')
      }
    }
  },
  watch: {
  },
  computed: {},
  methods: {
    getAuthorizationToken() {
      // Intenta obtener el token desde localStorage
      const tokenFromStorage = localStorage.getItem('accessToken');

      // Si el token se pasa como prop, usa ese token
      if (this.token) {
        return `Bearer ${this.token}`;
      } else if (tokenFromStorage) {
        return `Bearer ${tokenFromStorage}`;
      }

      // Si no hay ningún token, retorna null
      return null;
    },

    async makeAuthorizedRequest(url, params) {
      const authToken = this.getAuthorizationToken();
      
      // Si no hay token, no se realiza la solicitud
      if (!authToken) {
        console.log('No se encontró ningún token de autorización.');
        return;
      }

      try {
        // Configura el token en los headers de la solicitud
        const response = await this.$http.get(url, {
          headers: {
            Authorization: authToken
          },
          params: params
        });
        return response;
      } catch (error) {
        console.error('Error en la solicitud:', error);
        throw error; // Re-lanza el error para manejarlo en donde se llame
      }
    },
    // get data
    getDisplaysData () {
      this.openLoadingDisplaysCards()
      const params = {
        filters: {
          statisticType: 'displays',
          date: {
            from: this.filterDateCampaigns.from,
            to: this.filterDateCampaigns.to
          }
        }
      };

      this.makeAuthorizedRequest(`${appConfig.apiUrl}/stats/kpi`, params)
        .then((response) => {
          this.stats.displays_active = response.data.displays_active
          if (this.stats.displays_active.length > 0) {
            this.stats.displays_active_count = 0

            this.stats.displays_active.forEach((item) => {
              this.stats.displays_active_count += item.total_count
            })
          }
          //calculate percent of displays active
          this.stats.percent_display_active = getPercentDisplaysActive({
            displayCount: this.stats.displays_active_count,
            q: this.currentQ
          })

          // Displays for sales
          this.stats.displays_for_sale = response.data.displays_for_sale
          if (this.stats.displays_for_sale.length > 0) {
            this.stats.displays_for_sale_count = 0

            this.stats.displays_for_sale.forEach((item) => {
              this.stats.displays_for_sale_count += item.total_count
            })
          }

          // Displays active with camera
          this.stats.displays_active_camlytics = response.data.displays_active_camlytics
          if (this.stats.displays_active_camlytics.length > 0) {
            this.stats.displays_active_camlytics_count = 0

            this.stats.displays_active_camlytics.forEach((item) => {
              this.stats.displays_active_camlytics_count += item.total_count
            })
          }
        }).catch((error) => {
          this.reportError(error)
          console.log(error)
        }).finally(()=>{
            this.closeLoadingDisplaysCards()
        })
    },
    getCampaignsData () {
      this.openLoadingCampaigsCards()
      const params = {
        filters: {
          statisticType: 'campaigns',
          date: {
            from: this.filterDateCampaigns.from,
            to: this.filterDateCampaigns.to
          }
        }
      };

      this.makeAuthorizedRequest(`${appConfig.apiUrl}/stats/kpi`, params)
        .then((response) => {
          // this.stats.content_display_campaigns_active = response.data.content_display_campaigns_active > 0 ? response.data.content_display_campaigns_active : '-'          
          
          //render
          //campaigns active count
          this.stats.campaigns_active_count = response.data.campaigns_active.length

          //display campaigns active
          this.stats.display_campaigns_active = response.data.display_campaigns_active[0].count_display > 0 ? response.data.display_campaigns_active[0].count_display : '-'
          

          //content display
          this.stats.content_display_campaigns_active = response.data.content_display_campaigns_active > 0 ? response.data.content_display_campaigns_active : '-'          
        })
        .catch((error) => {
          this.reportError(error)
          console.log(error)
        })
        .finally(()=>{
            this.closeLoadingCampaigsCards()
        })
    },
    getSalesByCountries () {
      const params = {
        filters: {
          statisticType: 'campaigns',
          date: {
            from: this.datesCurrentQ.from,
            to: this.datesCurrentQ.to
          }
        }
      };

      this.makeAuthorizedRequest(`${appConfig.apiUrl}/stats/kpi`, params)
        .then((response) => {
          //Sales by country
          this.stats.total_price_with_profit_usd_by_country = response.data.total_price_with_profit_usd_by_country

          this.stats.total_price_with_profit_usd_by_country.forEach((item) => {
            item.sum = this.currencyFormatter(item.sum, 'USD')
            item.country = item.country ? item.country : '-'
            item.country_code = getCountryCode(item.country)
            item.percent = getQSalesPercentByCountry({
              country: item.country,
              q: this.currentQ,
              sum: item.sum
            })
          })
        })
        .catch((error) => {
          this.reportError(error)
          console.log(error)
        })
    },
    getChartInfo () {
      const params = {
        filters: {
          statisticType: 'campaigns',
          date: {
            from: moment().startOf('month').format('YYYY-MM-DD'),
            to: moment().endOf('month').format('YYYY-MM-DD')
          }
        }
      };

      this.makeAuthorizedRequest(`${appConfig.apiUrl}/stats/kpi`, params)
        .then((response) => {
          const indexCurrentMonth = new Date().getMonth() + 1
          const currentTotalUsd = response.data.total_price_with_profit_usd > 0 ? response.data.total_price_with_profit_usd : 0

          const realData = [90845, 88042, 225542, 204040, 193946, 310132, 247314, 223216.88, 298800, 503000, 356820]

          if (realData.length < indexCurrentMonth) {
            while (realData.length < indexCurrentMonth - 1) {
              realData.push(0)
            }
          }

          realData.push(currentTotalUsd)

          this.series = [
            {
              name: 'OKR',
              type: 'column',
              data: getToCurrentMonthOKR()
            },
            {
              name: 'Real',
              type: 'line',
              data: realData
            }
          ]

          //Current month data
          this.stats.total_price_with_profit_usd = currentTotalUsd

          //get average
          const totalOKR = getSumOKR()
          const totalRealData = realData.reduce((acc, curr) => acc + curr, 0)
          
          const average = (totalRealData / totalOKR) * 100
          this.average = average.toFixed(1)
        })
        .catch((error) => {
          this.reportError(error)
          console.log(error)
        })
    },
    //
    openLoadingDisplaysCards () {
      const containers = document.querySelectorAll('.display_stats')

      containers.forEach((container) => {
        this.$vs.loading({
          container,
          scale: 0.6,
          type: 'border'
        })
      })
    },
    closeLoadingDisplaysCards () {
      this.$vs.loading.close('.display_stats > .con-vs-loading')
    },
    currencyFormatter (value, currency) {
      const twoDecimals = parseFloat(value).toFixed(2)
      const formatted = twoDecimals.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return currency === 'ARS' ? `$ ${formatted}` : `US$ ${formatted}`
    },
    openLoadingCampaigsCards () {
      const containers = document.querySelectorAll('.campaigns_stats')
      containers.forEach(container => {
        this.$vs.loading({
          container,
          scale: 0.6,
          type: 'border'
        })
      })
    },
    closeLoadingCampaigsCards () {
      this.$vs.loading.close('.campaigns_stats > .con-vs-loading')
    },
    reportError (message) {
      this.$vs.notify({
        title: 'Error',
        text: message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    }
  },
  mounted() {
    try {
      const token = this.getAuthorizationToken()
      
      if (!token) {
        this.reportError("No se encontró un token de autenticación.")
        return
      }
      this.getDisplaysData()
      this.getCampaignsData()
      this.getSalesByCountries()
    } catch (error) {
      console.log(error)
    }
  },
  created() {
    try {
      const token = this.getAuthorizationToken()
      
      console.log(token)

      if (!token) {
        return
      }
      this.getChartInfo()
    } catch (error) {
      console.log(error)
    }
  }
}
</script>